<script lang="ts" setup>
import StickySidebar from 'angger-sticky-sidebar-v2'

interface Props {
  rootClass?: string
  topSpacing?: number | ((sidebar: HTMLElement) => number)
  bottomSpacing?: number | ((sidebar: HTMLElement) => number)
  containerSelector?: string | boolean
  stickyClass?: string | boolean
  minWidth?: number
}

const props = withDefaults(defineProps<Props>(), {
  rootClass: '',
  topSpacing: 0,
  bottomSpacing: 0,
  containerSelector: false,
  stickyClass: false,
  minWidth: 0,
})

const stickySidebarRef = ref<HTMLElement | null>(null)
const stickySidebar = ref<StickySidebar | null>(null)

onMounted(() => {
  stickySidebar.value = new StickySidebar(stickySidebarRef.value, {
    topSpacing: props.topSpacing,
    bottomSpacing: props.bottomSpacing,
    containerSelector: props.containerSelector,
    innerWrapperSelector: '.inner-wrapper-sticky',
    stickyClass: props.stickyClass,
    minWidth: props.minWidth,
  })
})
</script>

<template>
  <div ref="stickySidebarRef">
    <div class="inner-wrapper-sticky">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.sidebar {
  will-change: min-height;
}

.inner-wrapper-sticky {
  transform: translate(0, 0); /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}
</style>
